<template>
  <vca-card>
    <h2>{{ $t("profile.events.header") }}</h2>
    <vca-card class="shadowed vca-center">
      <EventTable :list="current" />
      <vca-popup
        v-if="current != null"
        :show="current != null"
        :title="$t('profile.events.popup.header', { 0: current.name })"
        @close="current = null"
      >
        <AccountEvent @close="close()" :event="current.event" />
      </vca-popup>
    </vca-card>
  </vca-card>
</template>

<script>
import { mapGetters } from "vuex";
import EventTable from "@/components/account/events/AccountEventTable";
import AccountEvent from "@/components/account/events/AccountEventApplication";
export default {
  name: "AccountEvents",
  components: { EventTable, AccountEvent },
  created() {
    this.current = null;
    this.$store
      .dispatch({ type: "events/participations/listUser" })
      .then((response) => {
        this.$store.commit(
          "user/participations/list",
          !response || response.length == 0 ? [] : response
        );
      });
  },
  methods: {
    close() {
      this.current = null;
    },
  },
  computed: {
    ...mapGetters({
      user: "user/current",
    }),
    current: {
      get() {
        return this.$store.state.user.participations.current;
      },
      set(value) {
        this.$store.commit("user/participations/current", value);
      },
    },
  },
};
</script>
